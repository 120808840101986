:root {
  --color-primary: rgb(0, 139, 201);
  --color-secondary: #23527c;
  --color-accent: rgb(52, 23, 117);
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
/* Typography */

html {
  /* 62.5% of 16px = 10px */
  font-size: 62.5%;
}

body {
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 2.4rem;
  line-height: 1.5;
  color: var(--color-body);
}

/* Links */
a {
  text-decoration: none;
}

/* Icons */
.icon {
  width: 60px;
  height: 60px;
}

.icon--primary {
  fill: var(--color-primary);
}

.icon--white {
  fill: white;
}

.icon--border {
  border: rgb(0, 139, 201) solid 1;
}

.icon-container {
  background: var(--color-primary);
  width: 60px;
  height: 60px;
  border-radius: 20%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Lists */
.list {
  list-style: none;
  padding-left: 0;
  color: var(--color-headings);
}

.list--inline .list__item {
  display: inline-block;
  margin-right: 2rem;
}

.list--tick .list__item {
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  .list--tick .list__item {
    padding-left: 0;
  }
}

/* Main area*/

.main__content {
  border-top: 1px solid lightgray;
}

/* Navbar */
.nav {
  background: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.nav.collapsible {
  display: none;
}

.nav.collapsible.collapsible--expanded {
  display: initial;
}

.nav__brand {
  padding-top: 1.2rem;
  padding-bottom: 0.7rem;
  padding-left: 10%;
  align-items: center;
  height: 90%;
}

.nav__item {
  padding: 0.5rem 2rem;
  border-bottom: 1px solid rgb(84, 74, 105);
  background-color: var(--color-primary);
  text-align: center;
  transition: 0.5s;
}

.nav__item > a {
  color: #d2d0db;
}

.nav__item > a:hover {
  color: #fff;
}

.nav__toggler {
  margin: 1rem;
  opacity: 0.5;
  transition: box-shadow 0.15s;
  cursor: pointer;
  display: block;
  margin-left: auto;
}

.collapsible--expanded.nav__toggler {
  opacity: 1;
  box-shadow: 0 0 0 3px rgb(143, 130, 130);
  border-radius: 5px;
}

.nav.collapsible.collapsible--expanded {
  padding: 0;
  margin: 0;
}

.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.list.nav__list.collapsible__content {
  margin-top: 0;
}

.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .nav {
    height: 70px;
    align-items: center;
    align-content: center;
    padding: 0;
  }

  .nav.collapsible {
    display: block;
  }

  .nav__toggler {
    display: none;
  }

  .nav__list {
    width: auto;
    display: flex;
    flex-grow: 1;
    font-size: 1.5rem;
    height: 100%;
    align-items: center;
    table-layout: fixed;
  }

  .nav__item {
    width: auto;
    border: none;
    height: 100%;
    padding: 0;
    display: table;
    flex-grow: 1;
  }

  .nav__item > a {
    display: table-cell;
    vertical-align: middle;
  }

  .nav__item:hover {
    background-color: var(--color-secondary);
  }

  .nav.collapsible {
    padding: 0;
  }

  .collapsible__content {
    max-height: 100vh;
    opacity: 1;
  }
}

@media screen and (min-width: 900px) {
  .nav {
    height: 80px;
  }
  .nav__list {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1140px) {
  .nav {
    height: 90px;
  }
  .nav__list {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1330px) {
  .nav {
    height: 80px;
    display: block;
    padding: 0 10%;
    background-color: var(--color-primary);
  }
  .nav__list {
    font-size: 2.5rem;
  }
}

.hero {
  position: relative;
}

.hero__picture {
  padding: 0;
  width: 100%;
  display: block;
}

.hero__text {
  padding: 0 10%;
  display: block;
  color: var(--color-accent);
}

.hero__text__background {
  position: absolute;
  background: rgba(204, 204, 204, 0.8);
  display: block;
  width: 55%;
  height: 13.5rem;
  top: 2rem;
  right: 2rem;
  border-radius: 10px;
}

.hero__caption {
  margin: 0;
  padding: 1rem 1rem;
  font-size: 1.3rem;
  width: 100%;
}

.hero__pitch {
  position: absolute;
  padding: 0 1rem;
  font-size: 1.2rem;
  top: 4rem;
  right: 1rem;
  text-align: justify;
}

.hero__details {
  position: absolute;
  left: 2rem;
  bottom: 2rem;
  color: white;
  background-color: var(--color-accent);
  font-size: 2rem;
  padding: 1rem 2rem;
  transition: 0.3s;
}

.hero__details:hover {
  box-shadow: 3px 3px 10px 3px rgb(137, 133, 143);
}

@media screen and (min-width: 768px) {
  .hero__text__background {
    position: absolute;
    background: rgba(204, 204, 204, 0.8);
    display: block;
    width: 45%;
    height: auto;
    top: 1.5rem;
  }

  .hero__pitch {
    position: unset;
    margin-top: 0;
  }

  .hero__details {
    font-size: 1.2rem;
    bottom: 1rem;
    left: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero__text__background {
    background: rgba(204, 204, 204, 0.5);
    width: 35%;
    top: 4rem;
    right: 4rem;
  }

  .hero__caption {
    font-size: 2.4rem;
    width: 100%;
    display: block;
  }

  .hero__pitch {
    font-size: 1.8rem;
    top: 4rem;
    display: block;
  }

  .hero__details {
    font-size: 2.4rem;
    bottom: 2rem;
    left: 2rem;
  }
}

/* Producer*/
.producer {
  display: block;
  padding: 0 10%;
}

.producer__title {
  width: 100%;
  text-align: center;
  color: var(--color-secondary);
  margin-top: 0;
}

.producer__container {
  padding: 1rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
}

.producer__item {
  width: 300px;
  height: 450px;
  border: 1px solid gray;
  border-radius: 15px;
  margin: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.producer__name {
  color: var(--color-primary);
  border-bottom: 1px solid gray;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.producer__main {
  object-fit: cover;
  max-height: 250px;
}

.producer__anchor {
  width: 100%;
  display: flex;
  justify-content: center;
}

.producer__logo {
  width: 60%;
  height: 80px;
  padding: 0rem 2rem;
  margin: 0;
  border: 2px solid darkblue;
  border-radius: 20px;
  align-content: center;
  transition: 0.3s;
}

.producer__logo:hover {
  box-shadow: 0 0 3px 2px rgb(107, 75, 158);
}

.producer__astex {
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}

.producer__logo__medos {
  padding: 2rem 2rem;
}

.producer__logo__konig {
  padding: 0 0.7rem;
}

/*Footer*/
.footer {
  height: 140px;
  background-color: var(--color-primary);
  position: relative;
  font-size: 1.8rem;
  font-weight: 200;
}

.footer__musician {
  position: absolute;
  right: 2.5rem;
  bottom: 3.3rem;
}

.footer__autdio {
  position: absolute;
  right: 2rem;
  height: 3rem;
  bottom: 2rem;
}

.footer__gdpr {
  position: absolute;
  color: var(--color-accent);
  left: 1rem;
  top: 1rem;
  transition: color 0.3s;
}

.footer__gdpr1 {
  position: absolute;
  color: var(--color-accent);
  left: 1rem;
  bottom: 2rem;
  transition: color 0.3s;
}

.footer__gdpr:hover {
  color: white;
}

@media screen and (min-width: 1330px) {
  .footer {
    height: 110px;
    font-size: 2rem;
  }
}

/*collapsible__header*/

.product__collapsible__header {
  margin: 0 2%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  display: flex;
  justify-content: space-between;
}

.product__collapsible__header h2 {
  margin: 0;
  color: var(--color-primary);
  font-size: 2.5rem;
}

.product__collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}

.product__collapsible--expanded .product__collapsible__content {
  max-height: 100%;
  opacity: 1;
}

.product__toggler {
  border: 1px solid lightgray;
}

.chevron::before {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: "";
  display: inline-block;
  height: 3rem;
  position: relative;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 3rem;
  top: 0.4rem;
  right: 2rem;
  transform: rotate(45deg);
  color: var(--color-primary);
  transition: all 0.3s;
}

.chevron.collapsible--expanded:before {
  top: -0.5rem;
  right: 2rem;
  transform: rotate(135deg);
}

.chevron:hover::before {
  color: var(--color-accent);
}

@media screen and (min-width: 768px) {
  .product__collapsible__header {
    margin: 0 10%;
  }

  .product__collapsible__header h2 {
    font-size: 3.5rem;
  }

  .chevron::before {
    top: 1.2rem;
  }
  .chevron.collapsible--expanded:before {
    top: 0.5rem;
  }
}

/*Product page styles*/
.product {
  display: flex;
  margin: 0%;
  padding-top: 1rem;
  background-color: white;
}

.product__images {
  width: 50%;
}

.product__image img {
  width: 100%;
  height: 100%;
}

.product__appendix {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product__name {
  font-size: 3.5rem;
  display: block;
  padding-bottom: 1rem;
  border-bottom: 1px solid grey;
  width: 100%;
  margin-top: 1rem;
  text-align: center;
}

.product__color__container {
  padding-top: 0.5rem;
}

.product__color__heading {
  margin-top: 8rem;
  text-align: center;
}

.product__colors {
  display: flex;
  flex-wrap: wrap;
}

.product__color {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 1rem;
  margin-left: 3rem;
}

.product__color__item {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.product__color__name {
  padding-left: 1rem;
}

.product__detail__title {
  margin-top: 5rem;
  margin-bottom: 5rem;
  text-align: center;
}

.product__detail__table {
  width: 97%;
  margin: 0 auto 5rem auto;
  display: table;
  border-color: grey;
  border-collapse: collapse;
  border: 1px solid grey;
}

.product__detail__table tr,
td {
  border: 1px solid grey;
  padding: 1rem 0;
  font-size: 1.5rem;
}

.product__detail__table tbody {
  display: table-row-group;
}

.product__detail__name {
  text-align: center;
  color: #939597;
}

.product__detail__data {
  text-align: center;
  color: #404041;
}

.product__description p {
  padding-left: 2rem;
}

.product__description__title {
  text-align: center;
  margin-top: 2rem;
}

.producer__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
}

@media screen and (min-width: 768px) {
  .product {
    margin: 0 10%;
  }

  .product__name {
    padding-bottom: 1rem;
    border-bottom: 1px solid grey;
    width: 90%;
    margin: 2rem auto 0 auto;
    text-align: center;
  }

  .product__color__heading {
    margin-top: 6rem;
  }

  .product__color {
    width: 50%;
  }

  .product__detail__title {
    margin-top: 6rem;
  }

  .product__detail__table {
    width: 90%;
  }
}

@media screen and (min-width: 1520px) {
  .product__data,
  .product__images {
    width: 50%;
  }

  .product__color__heading {
    margin-top: 6rem;
    margin-bottom: 4rem;
    text-align: left;
  }

  .product__details {
    margin-top: 5rem;
  }

  .product__detail__title {
    text-align: left;
  }

  .product__detail__table {
    width: 90%;
    margin-left: 0;
  }

  .product__color {
    margin: 0;
  }

  .product__description__title {
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .product__description p {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.product__category__name {
  color: var(--color-accent);
  font-size: 3rem;
  text-align: center;
}

.producer__description {
  padding: 0 3rem;
  margin: 1rem 0;
  text-align: justify;
  color: var(--color-accent);
  display: block;
}

.producer__caption p {
  text-align: center;
}

.producer__caption__logo {
  display: flex;
}

.producer__caption__logo img {
  margin: 2rem auto 1rem auto;
  padding: 3rem;
  border: 2px solid darkblue;
  border-radius: 20px;
}

.producer__caption__logo:hover img {
  box-shadow: 0 0 3px 2px rgb(107, 75, 158);
}

.producer__description {
  text-align: center;
}

/*Gdpr page*/
.data__gdpr > h2 {
  text-align: center;
}

.data__gdpr--alphalist {
  list-style-type: lower-alpha;
}

.data__gdpr--topics {
  font-size: 4rem;
  margin-left: 3rem;
}

.data__gdpr--topic {
  font-size: 2.5rem;
}

.data__gdpr--general {
  margin-left: 3rem;
}

.data__gdpr--nolisttype {
  list-style-type: none;
}
